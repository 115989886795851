<template>
  <v-autocomplete
    v-model="selected"
    :search-input.sync="search"
    :loading="loading"
    :items="filteredItems"
    label="Influencer"
    item-text="full_name"
    item-value="uuid"
    no-filter
    :return-object="returnObject"
    :clearable="clearable"
    :error-messages="errorMessages"
    :disabled="disabled"
  >
    <v-list slot="no-data">
      <v-list-item>
        <v-list-item-title>No result</v-list-item-title>
      </v-list-item>
    </v-list>

    <template slot="item" slot-scope="data">
      <v-list-item-content class="avatar-content">
        <v-avatar v-if="avatar" color="primary" class="mr-5">
          <template v-if="data.item.channels[0]">
            <img :src="data.item.user.avatar_url" />
          </template>
          <span v-else class="font-weight-bold white--text">
            {{ data.item.user.first_name.charAt(0).toUpperCase() }}
          </span>
        </v-avatar>
        <div class="">
          <v-list-item-title>
            {{ data.item.user.first_name + " " + data.item.user.last_name }}
          </v-list-item-title>
          <v-list-item-subtitle :class="avatar ? 'ml-20' : ''"
            >{{ data.item.gender === "MALE" ? "Male" : "Female" }} /
            {{ data.item.birthday | date_to_age }}</v-list-item-subtitle
          >
        </div>
      </v-list-item-content>
    </template>
  </v-autocomplete>
</template>
<script>
import date_to_age from "@/helpers/filters/date_to_age";
import { mapActions } from "vuex";

export default {
  filters: { date_to_age },
  props: {
    value: {
      required: true,
    },
    returnObject: {
      required: false,
      type: Boolean,
      default: true,
    },
    avatar: {
      required: false,
      type: Boolean,
      default: false,
    },
    clearable: {
      required: false,
      type: Boolean,
      default: true,
    },
    errorMessages: {
      required: false,
      default: null,
    },
    disabled: {
      required: false,
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    items: [],
    search: "",
    loading: false,
  }),
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
    filteredItems() {
      return this.items.map((item) => {
        item.full_name = item.user.first_name + " " + item.user.last_name;
        return item;
      });
    },
  },
  methods: {
    ...mapActions("core", ["getInfluencers"]),
    pushInfluencer(influencer) {
      this.items = [influencer];
      this.search = influencer.user.full_name;
    }
  },
  watch: {
    search: {
      handler: function() {
        this.loading = true;
        let params = {
          limit: 25,
          search: this.search ? this.search : " ",
          page: 1,
        };
        this.getInfluencers(params).then((influencers) => {
          this.items = influencers.response;
          this.loading = false;
        });
      },
    },
  },
};
</script>
<style lang="scss">
.v-list .v-list-item .avatar-content {
  display: flex;
  align-items: center;
  flex-wrap: unset;
  flex: none;
  overflow: visible;
  .v-list-item__title {
    color: var(--v-teal-base) !important;
  }
  .v-list-item__subtitle {
    color: var(--v-primary-base) !important;
  }
  .v-avatar {
    flex: none;
  }
}
</style>
