<template>
  <div>
    <template v-if="user.business && user.business.promotion_code_tracking_approved_at === null">
      <v-card>
        <v-card-title>
          <div>
            <div class="headline">Influencer promo codes</div>
            <span class="subtitle-1"
              >Missing approval. Contact Success Manager.</span
            >
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <div class="subheading font-weight-medium">
            What does that mean?
          </div>
          <p>
            The influencers do not receive commission for direct sales through
            discount codes. To motivate the influencers to create more traffic, 
            we recommend that they receive a personal discount code. However, 
            in order for them to receive commission for the sales they generate 
            through discount codes, you need to be approved for discount code tracking.
          </p>

          <v-btn color="primary" :to="{ name: 'contact' }"
            >Contact Success Manager</v-btn
          >
        </v-card-text>
      </v-card>
    </template>

    <template v-if="user.business && user.business.promotion_code_tracking_approved_at !== null">
      <v-card>
        <v-card-title>
          <div>
            <div class="headline">Influencer promo codes</div>
            <span class="subtitle-1"
              >Give the influencers the option to promote using personal discount / promotion codes</span
            >
          </div>
          <v-spacer></v-spacer>
          <div class="text-right mb-3">
            <v-btn
              color="primary"
              class="hidden-xs-only mr-0"
              @click="createDialog = true"
              >Add promo code</v-btn
            >
            <v-btn
              color="primary"
              class="hidden-sm-and-up"
              fixed
              bottom
              right
              fab
              @click="createDialog = true"
            >
              <v-icon>add</v-icon>
            </v-btn>
          </div>
        </v-card-title>
         <v-select
            :items="statuses"
            v-model="filters.active"
            item-value="value"
            item-text="label"
            class="mr-5 status-selection pl-5"
            label="Status"
            :menu-props="{ bottom: true, offsetY: true }"
          >
          </v-select>
        <v-card-text>
          <influencer-promotion-codes-data-table
            :items="promotionCodes"
            @deleted="deletePromotionCode($event)"
          ></influencer-promotion-codes-data-table>
        </v-card-text>
      </v-card>
      <add-promo-code
        v-if="createDialog"
        v-model="createDialog"
        @saved="save"
        @cancel="createDialog = false"
      />
    </template>
    <template v-if="isInfluencer">
      <v-card>
          <v-card-title>
            <div>
              <div class="headline">Promo codes</div>
            </div>
          </v-card-title>
          <v-card-text class="primary--text">
            <div class="subheading font-weight-medium">
              What does that mean for me?
            </div>
            <p>
              If you're using personal promotion code, which
              <span class="font-weight-bold">can't</span> be found on the following
              list, it means that we <span class="font-weight-bold">can't</span>
              register any sales. Personal promotion codes are an extra
              option to assign sales you've promoted.
            </p>
            <p>We recommend that you only use promotion codes we're tracking sales on!</p>
            
            <influencer-promotion-codes-data-table
              class="mt-5"
              :items="promotionCodes"
            ></influencer-promotion-codes-data-table>
          </v-card-text>
        </v-card>
    </template>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import InfluencerPromotionCodesDataTable from "../../data-tables/InfluencerPromotionCodesDataTable";
import AddPromoCode from "../../business/dialogs/AddPromoCode";
import search_filters_url from "@/helpers/mixins/search_filters_url";

export default {
  components: { InfluencerPromotionCodesDataTable, AddPromoCode },
  mixins: [search_filters_url],

  data: () => ({
    createDialog: false,
    promotionCodes: [],
    filters: {
      active: true
    },
    statuses: [
      {
        label: "Active",
        value: true
      },
      {
        label: "Deleted",
        value: false
      }
    ]
  }),
  watch: {
    filters: {
      handler: function() {
        this.getCodes();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("core/auth", ["user"]),
    ...mapGetters("core/auth", ["isBusiness", "isInfluencer"]),
  },
  methods: {
    ...mapActions("business", ["getPromotionCodes", "addPromotionCode"]),
    ...mapActions("influencer", ["getInfluencerPromotionCodes"]),

    save(data) {
      let params = {
        ...data,
        influencer_uuid: data.influencer.uuid,
      };
      delete params["influencer"];
      this.addPromotionCode(params).then(
        influencerPromotionCode => {
          this.setSnackSuccess("Saved");
          this.createDialog = false;
          this.promotionCode = {};
          this.promotionCodes.push(influencerPromotionCode);
        },
        () => {
          this.setSnackError("Error");
        }
      );
    },
    getCodes() {
      const params = {
        ...this.filters
      };
      this.setUrlSearchFilters(this.filters);
      if(this.isBusiness) {
         this.getPromotionCodes(params).then(
        influencerPromotionCodes => {
          this.promotionCodes = influencerPromotionCodes;
        },
        () => {
          this.setSnackError("Error");
        }
      );
      }
      if(this.isInfluencer) {
        this.getInfluencerPromotionCodes()
          .then(influencerPromotionCodes => {
            this.promotionCodes = influencerPromotionCodes;
          })
          .catch(() => {
            this.setSnackError("Error");
          });
      }
     
    },
    deletePromotionCode(promotionCode) {
      let index = this.promotionCodes
        .map((item) => item.uuid)
        .indexOf(promotionCode.uuid);

      if (index >= 0) {
        this.promotionCodes.splice(index, 1);
      }
    },
  },
  created() {
    if (Object.keys(this.$route.query).length > 0) {
      const filters = this.getUrlSearchFilters();
      this.filters = this.cloneDeep(filters);
    } else {
      this.getCodes();
    }
  },
};
</script>
<style lang="scss">
.status-selection {
  max-width: 130px;
  .v-input__append-inner {
    margin-top: 4px !important;
  }
}
</style>
