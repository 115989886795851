<template>
  <v-dialog v-model="showing" :width="width" content-class="add-promocode">
    <v-card>
      <v-card-title class="pb-0">
        <div class="headline">Add promo code</div>
      </v-card-title>
      <v-card-text>
        <influencer-autocomplete
          avatar
          v-model="promotionCode.influencer"
          :return-object="true"
          v-validate="'required'"
          name="Influencer"
          :error-messages="errors.collect('Influencer')"
        ></influencer-autocomplete>
        <div class="label">
          Promo code
        </div>
        <v-text-field
          v-model="promotionCode.code"
          outlined
          v-validate="'required|min:2'"
          name="Code"
          :error-messages="errors.collect('Code')"
        ></v-text-field>
        <div class="label">
          Description
        </div>
        <v-text-field
          v-model="promotionCode.description"
          v-validate="'required|min:2'"
          :error-messages="errors.collect('Beskrivelse')"
          name="Description"
          outlined
        ></v-text-field>
        <p class="label">
          Commission
        </p>
        <div class="d-flex">
          <v-select
            outlined
            :items="types"
            v-model="selectedType"
            item-value="value"
            item-text="label"
            class="mr-5 type-selection"
          >
          </v-select>

          <v-text-field
            v-model.number="amount"
            :name="isTypePercent ? 'fixed' : 'percent'"
            outlined
            v-validate="isTypePercent ? 'max_value:100|numeric' : 'numeric'"
            :disabled="!selectedType"
            :reverse="true"
            :error-messages="
              errors.collect(isTypePercent ? 'fixed' : 'percent')
            "
            :prefix="isTypePercent ? '%' : 'DKK'"
          ></v-text-field>
        </div>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text class="mr-0" @click="$emit('cancel', true)"
          >CANCEL</v-btn
        >
        <v-btn color="primary" class="mr-0" :loading="loading" @click="save()"
          >CREATE</v-btn
        >
      </v-card-actions>
    </v-card>
    <v-card v-if="promotionCode.influencer" width="60%">
      <v-card-text class="pt-10">
        <v-list-item-content class="avatar-content">
          <div>
            <v-list-item-title class="mb-2">
              {{
                promotionCode.influencer.user.first_name +
                  " " +
                  promotionCode.influencer.user.last_name
              }}
            </v-list-item-title>
            <v-list-item-subtitle
              >{{
                promotionCode.influencer.gender === "MALE" ? "Male" : "Female"
              }}
              /
              {{
                promotionCode.influencer.birthday | date_to_age
              }}</v-list-item-subtitle
            >
          </div>
          <div class="greytxt--text mt-5 mb-1">Followers</div>
          <div
            class="primary--text font-weight-bold mb-2"
            v-for="channel in promotionCode.influencer.channels"
            :key="channel.uuid"
          >
            <div>
              <v-icon class="mr-2">{{ channel.channel.icon }}</v-icon>
              {{ channel.followers | friendly_numbers }} -
              {{ channel.channel.display_name }}
            </div>
            <div class="d-flex align-items-center mt-2">
              <v-avatar
                v-if="promotionCode.influencer.channels.length"
                color="primary"
                class="mr-5"
              >
                <template v-if="promotionCode.influencer.channels[0]">
                  <img
                    :src="
                      promotionCode.influencer.channels[0].profile_image_url
                    "
                  />
                </template>
                <span v-else class="font-weight-bold white--text">
                  {{
                    promotionCode.influencer.user.first_name
                      .charAt(0)
                      .toUpperCase()
                  }}
                </span>
              </v-avatar>
              <div class=" mt-2">
                <div>
                  {{ channel.username }}
                </div>
                <div class="greytxt--text">
                  {{ channel.channel.display_name }}
                </div>
              </div>
            </div>
          </div>
        </v-list-item-content>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import InfluencerAutocomplete from "../autocomplete/InfluencerAutocomplete";
import date_to_age from "@/helpers/filters/date_to_age";
import friendly_numbers from "@/helpers/filters/friendly_numbers";

export default {
  components: { InfluencerAutocomplete },
  filters: { date_to_age, friendly_numbers },
  props: {
    value: {
      required: true,
      default: false
    }
  },
  data: () => ({
    loading: false,
    amount: null,
    promotionCode: {
      code: "",
      influencer: null
    },
    selectedType: null,
    types: [
      {
        value: "cost_per_action_percent",
        label: "Percentage per sale",
      },
      {
        value: "cost_per_action_fixed",
        label: "Fixed lead price",
      },
    ],
  }),
  computed: {
    width() {
      return this.promotionCode.influencer ? "700px" : "400px";
    },
    isTypePercent() {
      return (
        this.selectedType && this.selectedType === "cost_per_action_percent"
      );
    },
    showing: {
      get() {
        return this.value !== null;
      },
      set() {
        this.$emit("input", null);
      },
    },
  },
  methods: {
    save() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
        this.promotionCode[this.selectedType] = parseInt(this.amount);

          this.$emit("saved", this.cloneDeep(this.promotionCode));
          this.loading = false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.add-promocode {
  display: flex;
  .v-card {
    &:first-child {
      border-radius: 4px 0 0 4px;
    }
    &:last-child {
      border-radius: 0 4px 4px 0;
    }
  }
}
.add-promocode .avatar-content {
  .v-list-item__title {
    color: var(--v-teal-base) !important;
  }
  .v-list-item__subtitle {
    color: var(--v-primary-base) !important;
  }
}
</style>
