<template>
  <div class="promocodes-wrapper">
    <v-expansion-panels v-if="$vuetify.breakpoint.xsOnly">
      <v-expansion-panel v-for="item in items" :key="item.id">
        <v-expansion-panel-header>
          <div class="mobile-header-wrap">
            <div class="mobile-table-column" v-if="isBusiness">
              <span>Influencer</span>
              <div class="avatar-column">
              <v-avatar color="primary" size="58" class="mr-5">
                <template v-if="item.influencer.channels[0]">
                  <img :src="item.influencer.channels[0].profile_image_url" />
                </template>
                <span v-else class="font-weight-bold white--text">
                  {{ item.influencer.user.first_name.charAt(0).toUpperCase() }}
                </span>
              </v-avatar>
              <router-link
                class="teal--text"
                :to="{
                  name: 'influencer',
                  params: { id: item.influencer.uuid },
                }"
              >
                {{ item.influencer.user.first_name }}
                {{ item.influencer.user.last_name }}
              </router-link>
            </div>
            </div>
            <div class="mobile-table-column influencer" v-else>
              <span>Business</span>
              <div>{{ item.business.display_name }}</div>
            </div>
            <div class="mobile-table-column">
              <span>Promo code</span>

              <v-btn color="secondary" class="copy-btn " dark>{{
                item.code
              }}</v-btn>
            </div>
          </div>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <div class="mobile-table-column">
            <span>Commission</span>
            <div v-if="item.cost_per_action_fixed">
              {{ item.cost_per_action_fixed | local_numbers }} Fixed lead price
            </div>
            <div v-else>
              {{ item.cost_per_action_percent | local_numbers }}% Percentage per
              sale
            </div>
          </div>
          <div class="mobile-table-column">
            <span>Description</span>
            <div>{{ item.description }}</div>
          </div>
           <v-btn
                color="primary"
                outlined
                dark
                class="w-100 mt-5"
                @click="copyCode(item.code)"
                retain-focus-on-click
                >Copy promo code</v-btn
              >
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
    <v-data-table
      v-else
      :headers="isBusiness ? headersBusiness : headersInfluencer"
      :items="items"
      :loading="loading"
      hide-default-footer
      :items-per-page="10000"
    >
      <v-progress-linear
        slot="progress"
        color="primary"
        indeterminate
      ></v-progress-linear>
      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.id" class="py-10">
            <td class="avatar-column" v-if="isBusiness">
              <v-avatar color="primary" size="58" class="mr-5">
                <template v-if="item.influencer.channels[0]">
                  <img :src="item.influencer.channels[0].profile_image_url" />
                </template>
                <span v-else class="font-weight-bold white--text">
                  {{ item.influencer.user.first_name.charAt(0).toUpperCase() }}
                </span>
              </v-avatar>
              <router-link
                class="teal--text"
                :to="{
                  name: 'influencer',
                  params: { id: item.influencer.uuid },
                }"
              >
                {{ item.influencer.user.first_name }}
                {{ item.influencer.user.last_name }}
              </router-link>
            </td>
            <td v-else>
              {{ item.business.display_name }}
            </td>
            <td>
              <v-tooltip top>
                <template v-slot:activator="{ on }">
                  <v-btn
                    color="secondary"
                    class="copy-btn "
                    dark
                    v-on="on"
                    @click="copyCode(item.code)"
                    retain-focus-on-click
                    >{{ item.code }}</v-btn
                  >
                </template>
                <span>{{ copyText }}</span>
              </v-tooltip>
            </td>
            <td class="text-center">
              <template v-if="item.cost_per_action_fixed">
                {{ item.cost_per_action_fixed | local_numbers }}
                <div class="greytxt--text subheading-descr">
                  Fixed lead price
                </div>
              </template>
              <template v-else
                >{{ item.cost_per_action_percent | local_numbers }}%
                <div class="greytxt--text subheading-descr">
                  Percentage per sale
                </div>
              </template>
            </td>
            <td>{{ item.created_at | utc_to_local_datetime }}</td>
            <td class="text-right">
                
              <v-btn color="primary" outlined icon @click="selectedCode = item" class="mr-2">
                <v-icon small>fal fa-eye</v-icon>
              </v-btn>
              <v-btn
                v-if="isBusiness"
                :disabled="!!item.deleted_at"
                color="primary"
                outlined
                icon
                @click="deletePromotionCode = item"
              >
                <v-icon small>fal fa-trash-alt</v-icon>
              </v-btn>
            </td>
          </tr>
        </tbody>
      </template>

      <div class="text-center" slot="no-data">
        No promotion codes found
      </div>
    </v-data-table>

    <v-dialog
      v-if="selectedCode !== null"
      v-model="selectedCodeDialog"
      width="100%"
      max-width="500px"
    >
      <v-card>
        <v-card-title class="pb-0">
          <div class="headline"></div>
        </v-card-title>
        <v-card-text>
          <div class="subheading font-weight-medium primary--text">
            Description
          </div>
          <p class="primary--text">{{ selectedCode.description }}</p>
        </v-card-text>
      </v-card>
    </v-dialog>

    <confirm-dialog
      v-model="deleteSelectedCodeDialog"
      text="Are you sure you want to delete this promo code?"
      @confirm="deleteAction()"
      btnText="Yes"
      btnCancelText="No"
    ></confirm-dialog>
  </div>
</template>

<script>
import local_numbers from "@/helpers/filters/local_numbers";
import utc_to_local_datetime from "@/helpers/filters/utc_to_local_datetime";
import ConfirmDialog from "../dialogs/ConfirmDialog";
import { mapActions, mapGetters } from "vuex";

export default {
  filters: {
    local_numbers,
    utc_to_local_datetime,
  },
  components: { ConfirmDialog },
  props: {
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data: () => ({
    copyText: "Copy",
     headersBusiness: [
      {
        text: "Influencer",
        value: "influencer.user.first_name",
        class: "header-color",
      },
      {
        text: "Promo code",
        value: "code",
        class: "header-color",
      },

      {
        text: "Commission",
        align: "center",
        class: "header-color",
      },
      {
        text: "Created",
        sortable: true,
        value: "created_at",
        class: "header-color",
      },
      {
        text: "",
        sortable: false,
      },
    ],
    headersInfluencer: [
      {
        text: "Business",
        value: "business.display_name",
      },
      {
        text: "Promo code",
        value: "code",
      },
      {
        text: "Commission",
        align: "center",
        value: "cost_per_action_fixed",
      },
      {
        text: "Created",
        sortable: true,
        value: "created_at",
      },
      {
        text: "",
        sortable: false,
      },
    ],
    deletePromotionCode: null,
    selectedCode: null,
  }),
  computed: {
    ...mapGetters("core/auth", ["isBusiness", "isInfluencer"]),
    selectedCodeDialog: {
      get() {
        return this.selectedCode !== null;
      },
      set() {
        this.selectedCode = null;
      },
    },
    deleteSelectedCodeDialog: {
      get() {
        return this.deletePromotionCode !== null;
      },
      set() {
        this.deletePromotionCode = null;
      },
    }
  },
  methods: {
    ...mapActions("business", ["removePromotionCode"]),

    copyCode(code) {
      navigator.clipboard.writeText(code);
      this.copyText = "Copied!";
      setTimeout(() => {
        this.copyText = "Copy";
      }, 1000);
    },
    deleteAction() {
      let params = {
        uuid: this.deletePromotionCode.uuid,
      };
      this.removePromotionCode(params)
        .then(() => {
          this.setSnackSuccess("Succes");
          this.$emit("deleted", this.deletePromotionCode);
          this.deletePromotionCode = null;
        })
        .catch((error) => {
          this.setSnackError("Error" + error);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.copy-btn {
  color: var(--v-accent-base) !important;
  border-radius: 44px;
  box-shadow: none;
  margin: 10px 0;
}
.mobile-header-wrap {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  div:last-child {
    margin-right: 20px;
  }
  .mobile-table-column.influencer div {
    margin-top: 20px;
  }
}
.mobile-table-column {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  &:last-child {
    text-align: center;
    .copy-btn {
      margin-top: 10px;
    }
  }
  span {
    font-size: 12px;
    color: var(--v-greytxt-base) !important;
  }
  div {
    font-size: 14px;
    color: var(--v-primary-base) !important;
  }
}
.subheading-descr {
  font-size: 11px;
}
.copy-btn.v-btn {
  box-shadow: none !important;
  border-radius: 44px;
  color: var(--v-teal-base) !important;
}
.avatar-column {
  display: flex;
  align-items: center;
  padding: 10px 0 !important;
  height: auto !important;
}
.header-color span,
.promocode-table-wrap td {
  color: var(--v-primary-base) !important;
}
</style>
